<template>
  <PflegeBaseComponentTemplate
    v-editable="props.blok"
    :data-uid="props.blok._uid"
    :heading="props.blok.heading"
    :headingTag="props.blok.headingTag"
    :textAlign="props.blok.textAlign"
    :backgroundColor="getPflegeColornameFromHex(props.blok.backgroundColor.value)"
    :subline="props.blok.subline"
    :sublineTag="props.blok.sublineTag"
    :colorSplat="props.blok.colorSplat"
    :imageUrl="props.blok.imageUrl.filename ?? undefined"
    :actionsAlignment="props.blok.actionsAlignment"
    :headingClass="props.blok.headingClass"
    :sublineClass="props.blok.sublineClass"
  >
    <template v-if="willRender(props.blok.content)" #default>
      <RichtextResolver :blok="props.blok.content" />
    </template>

    <template v-if="props.blok.actions?.length > 0" #actions>
      <WPElementButton v-for="button in props.blok.actions" :key="button._uid" :blok="button" />
    </template>
  </PflegeBaseComponentTemplate>
</template>

<script setup>
import { PflegeBaseComponentTemplate } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true
  }
})

const { getPflegeColornameFromHex } = usePflegeUtils()

const mainComponent = inject('mainComponent', null)
if (mainComponent) {
  mainComponent.addHeadline(props.blok.heading)
}
</script>
